export const translations = {
  'de': {
    'Name': '',
    'Description': '',
    'Submit': '',
    'Items.header': '',
    'Items.header.offline': '',
    'YYYY-MM-DD': '',
    'LoadingContent.message': '',
    'Email': '',
    'Password': '',
    'ForgotPassword': '',
    'ForgotPassword.here': '',
    'NewPassword': '',
    'NewPasswordConfirmation': '',
    'PasswordConfirmation': '',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': '',
    'Home.header.offline': '',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Name': '',
    'Description': '',
    'Submit': 'Submit',
    'Items.header': 'Welcome To Bakerplate',
    'Items.header.offline': 'Welcome To Offline Bakerplate',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Forgot your password? Recover',
    'ForgotPassword.here': 'here',
    'NewPassword': 'New Password',
    'NewPasswordConfirmation': 'New Password Confirm',
    'PasswordConfirmation': 'Password Confirm',
    'Number': 'Number',
    'Text': 'Text',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Welcome To Bakerplate',
    'Home.header.offline': 'Welcome To Offline Bakerplate',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao Bakerplate',
    'Items.header.offline': 'Ciao offline Bakerplate',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao Bakerplate',
    'Home.header.offline': 'Ciao offline Bakerplate',
  },
}
