/**
 * The navbar container
 */
import React from "react";
import { connect, useSelector } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { setLanguage } from "redux-i18n";
import PropTypes from "prop-types";
import { getCounterAttributes } from "../../../redux/counters";
/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  currentUser: state.account.currentUser,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: setLanguage,
      navigateTo: push,
    },
    dispatch
  );

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const HeaderTitle = ({ counterId }, context) => {
  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props
   */

  const counterData = useSelector((state) =>
    getCounterAttributes(state, counterId)
  );

  return (
    <p className="h6 font-weight-bold text-primary">{counterData?.name}</p>
  );
};

/**  define proptype for the 'translation' function  */
HeaderTitle.contextTypes = {
  t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTitle);
