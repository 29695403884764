/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { i18nState } from "redux-i18n";
import servWork from "./servWork/reducers";
import notifications from "./notifications/reducers";
import account from "./account/reducers";
import ingredients from "./ingredients";
import recipes from "./recipes";
import recipeSteps from "./recipe_steps";
import ingredientsGroups from "./ingredients_groups";
import allergens from "./allergens";
import recipeIngredients from "./recipe_ingredients";
import counters from "./counters";
import healthyBenefits from "./healthy_benefits";
import articles from "./articles";
import suppliers from "./suppliers";
import clients from "./clients";
import nutrients from "./nutrients";
import techniques from "./techniques";
import techniqueSteps from "./technique_steps";
import timeslots from "./timeslots";
import locations from "./locations";
import ingredientNutrients from "./ingredient_nutrients";
import certificates from "./certificates";
import products from "./products";
import dishes from "./dishes";
import users from "./users";

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports all reducers through the combineReducers function */
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    i18nState,
    servWork,
    notifications,
    account,
    ingredients,
    recipes,
    recipeSteps,
    ingredientsGroups,
    locations,
    healthyBenefits,
    recipeIngredients,
    allergens,
    counters,
    articles,
    nutrients,
    techniques,
    timeslots,
    techniqueSteps,
    suppliers,
    clients,
    ingredientNutrients,
    certificates,
    products,
    dishes,
    users,
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded reducers
  });

export default reducers;
