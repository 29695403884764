/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import notificationsSaga from "./notifications/sagas";
import { all } from "redux-saga/effects";
import accountSaga from "./account/sagas";
import dictionarySaga from "./dictionarySagas";
import serviceWorkerSaga from "./servWork/sagas";
import ingredientsSagas from "./ingredients/sagas";
import recipesSagas from "./recipes/sagas";
import recipeStepsSagas from "./recipe_steps/sagas";
import allergensSagas from "./allergens/sagas";
import countersSagas from "./counters/sagas";
import articlesSagas from "./articles/sagas";
import suppliersSagas from "./suppliers/sagas";
import clientsSagas from "./clients/sagas";
import ingredientsGroupsSagas from "./ingredients_groups/sagas";
import nutrientsSagas from "./nutrients/sagas";
import locationsSagas from "./locations/sagas";
import healthyBenefitsSagas from "./healthy_benefits/sagas";
import techniquesSagas from "./techniques/sagas";
import recipeIngredientsSagas from "./recipe_ingredients/sagas";
import timeslotsSagas from "./timeslots/sagas";
import techniquesStepsSagas from "./technique_steps/sagas";
import certificatesSagas from "./certificates/sagas";
import productsSagas from "./products/sagas";
import dishesSagas from "./dishes/sagas";
import usersSagas from "./users/sagas";
import dailyMenuSagas from "./daily_menus/sagas";
import weeklyMenuSagas from "./weekly_menus/sagas";
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    notificationsSaga(),
    dictionarySaga(),
    accountSaga(),
    serviceWorkerSaga(),
    ingredientsSagas(),
    recipesSagas(),
    recipeStepsSagas(),
    healthyBenefitsSagas(),
    allergensSagas(),
    countersSagas(),
    articlesSagas(),
    ingredientsGroupsSagas(),
    suppliersSagas(),
    recipeIngredientsSagas(),
    clientsSagas(),
    nutrientsSagas(),
    locationsSagas(),
    techniquesSagas(),
    timeslotsSagas(),
    techniquesStepsSagas(),
    certificatesSagas(),
    productsSagas(),
    dishesSagas(),
    usersSagas(),
    dailyMenuSagas(),
    weeklyMenuSagas(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
  ]);
}
