/**
 * The navbar container
 */
import React from "react";
import PropTypes from "prop-types";

const Footer = (props, context) => {
  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props
   */

  return (
    <div className="text-center pb-1 footer">
      <p>Copyright © 2022 | Organic Garden</p>
      <p>
        <a className="text-muted" href="https://www.organicgarden.de/impressum">
          {context.t("Impressum")} |
        </a>
        <a
          className="text-dark"
          href="https://www.organicgarden.de/datenschutz-nutritionmanagement"
        >
          {context.t("Datenschutz")}
        </a>
      </p>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Footer.contextTypes = {
  t: PropTypes.func,
};

export default Footer;
