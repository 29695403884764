export const defaultListSuccess = (state, action) => {
  const responseList = action.payload.obj.data || [];
  const resonseIds = action.payload.obj.data.map(a => parseInt(a.id));
  const responseHeaders = action.payload.headers;

  if (responseHeaders && responseHeaders['total-count'])
    state.listTotalCount = parseInt(responseHeaders['total-count'], 10);
  if (responseHeaders && responseHeaders['current-page'])
    state.listPage = parseInt(responseHeaders['current-page'], 10);
  state.loadingList = false;
  state.loadedList = true;
  state.loadedList = true;
  if (state.listPage > 1) {
    state.list = state.list.concat(responseList);
    state.idsList = state.idsList.concat(resonseIds);
  } else {
    state.list = responseList;
    state.idsList = resonseIds
  }
  responseList.forEach((entry) => (state.dictionary[entry.id] = entry));
};

export const defaultList = (state, action) => {
  console.log('calling list')
  state.loadingList = true;
  state.loadedList = false;
  state.listError = null;
  if (action.payload && action.payload.page) {
    state.listPage = action.payload.page;
  }
  if (state.listPage === 1){
    state.idsList = Object.keys(state.dictionary)
    state.list = Object.keys(state.dictionary).map(
      (entry) => state.dictionary[entry],
    );
  }
};

export const defaultListFail = (state, action) => {
  state.loadingList = false;
  state.loadedList = false;
  state.listError = action.payload.mess;
};
export const defaultShow = (state, action) => {
  state.loadingShow = true;
  state.loadedShow = false;
  state.showError = null;
  state.show = state.dictionary[action.payload] || {};
  state.showId = action.payload || null;
};
export const defaultShowSuccess = (state, action) => {
  state.loadingShow = false
  state.loadedShow = true
  state.show = action.payload
  state.dictionary[action.payload.id] = action.payload
};
export const defaultShowFail = (state, action) => {
  state.loadingShow = false;
  state.loadedShow = false;
  state.showError = action?.payload?.message;
};

export const defaultCreate = (state) => {
  state.creating = true;
  state.created = false;
  state.createError = null;
};
export const defaultCreateSuccess = (state, action) => {
  state.creating = false;
  state.created = true;
  state.show = action.payload
  state.dictionary[action.payload.id] = action.payload;
  state.createError = null;
};
export const defaultCreateFail = (state, action) => {
  state.creating = false;
  state.created = false;
  state.createError = action.payload.mess;
};

export const defaultUpdate = (state) => {
  state.updating = true;
  state.updated = false;
  state.updateError = null;
};
export const defaultUpdateSuccess = (state, action) => {
  state.updating = false;
  state.updated = true;
  state.show = action.payload;
  state.dictionary[action.payload.id] = action.payload;
};
export const defaultUpdateFail = (state, action) => {
  state.updating = false;
  state.updated = false;
  state.updateError = action.payload.mess;
};
export const defaultRemove = (state, action) => {
  state.removing = true;
  state.removed = false;
  state.removeError = null;
  state.list = state.list.filter((entry) => entry.id !== action.payload);
  delete state.dictionary[action.payload];
};
export const defaultRemoveSuccess = (state, action) => {
  state.removing = false;
  state.removed = true;
};
export const defaultRemoveFail = (state, action) => {
  state.removing = false;
  state.removed = false;
  state.removeError = action.payload.mess;
};

export const defaultAddToDictionary =  (state, action) => {
  state.dictionary[action.payload.id] = {...state.dictionary[action.payload.id], ...action.payload}
};