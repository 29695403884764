/**
 * The navbar container
 */
import React, { useEffect } from "react";
import classNames from "classnames";
import { Navbar } from "reactstrap";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { setLanguage } from "redux-i18n";
import FlashAlert from "../FlashAlert";
import PropTypes from "prop-types";
import Moment from "react-moment";
import ogLogo from "../../assets/images/OG_logo_frame.svg";
import accountActions from "../../redux/account";
import { showCounter } from "../../redux/counters";
import { getClientAttributes, showClient } from "../../redux/clients";
import HeaderTitle from "./utils/HeaderTitle";
import { useSelector } from "react-redux";
/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  lang: state.i18nState.lang,
  currentUser: state.account.currentUser,
  signedin: state.account.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: setLanguage,
      navigateTo: push,
      logout: accountActions.logout,
      showCounter,
      showClient,
    },
    dispatch
  );

/**
 * List of languages supported
 */
const languages = {
  it: "Italiano",
  en: "English",
  de: "Deutsch",
};
/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props, context) => {
  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props
   */

  const { alertMessage, alertColor, alertTimeout, showCounter, showClient } =
    props;

  const locationId = process.env.REACT_APP_LOCATION_ID;
  const counterId = process.env.REACT_APP_COUNTER_ID;
  const clientIdVariable = process.env.REACT_APP_CLIENT_ID;

  const clientId = parseInt(clientIdVariable);

  useEffect(() => {
    showCounter({ counterId, locationId });
    showClient(clientId);
  }, [counterId, locationId, clientId, showClient, showCounter]);

  const client = useSelector((state) => getClientAttributes(state, clientId));
  const primaryColor = client?.primary_color;

  return (
    <div
      style={props.style}
      className={classNames("navigationbar ", {
        "navigationbar--sticky": props.isSticky,
      })}
    >
      <Navbar
        expand="sm"
        className="m-0 pt-2 p-0"
        color={`${primaryColor ? primaryColor : "secondary"}`}
        style={{ background: `${primaryColor}` }}
      >
        <img className="p-1" src={ogLogo} width="80px" alt="og logo" />
        <div>
          <HeaderTitle counterId={counterId} />
          <p className="h4 justify-content-center font-weight-bold">
            Gesunder Genuss
          </p>
        </div>
        <div
          className="d-flex justify-content-center ml-5 wisag__container w-100 m-0"
          color="primary"
        >
          <img
            src={client?.logo}
            className="p-1"
            alt="client logo"
            width="40px"
          />
        </div>
      </Navbar>
      <div
        className="p-2 text-center font-weight-bold "
        style={{ background: "#78ada4", color: "white" }}
      >
        <Moment format="dd DD.MM">{props.date}</Moment>
      </div>

      {alertMessage && (
        <FlashAlert color={alertColor || "success"} timeout={alertTimeout}>
          {alertMessage}
        </FlashAlert>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar);
