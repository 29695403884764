/**
 * The Root container
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
// import { Sticky } from 'react-sticky';
import Navbar from "../components/Navbar";
import OfflineBar from "../components/OfflineBar";
import Loadable from "react-loadable";
import FourOFour from "./FourOFour";
import PrivateRoute from "../components/PrivateRoute";
import Footer from "../components/Footer";

/**
 * Loadable import for Dashboard container
 */
const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./Dashboard"),
  loading: () => null,
  modules: ["Dashboard"],
});
/**
 * Loadable import for Dashboard container
 */
const HealthyFact = Loadable({
  loader: () => import(/* webpackChunkName: "HealthyFact" */ "./HealthyFact"),
  loading: () => null,
  modules: ["HealthyFact"],
});
/**
 * Loadable import for Account container
 */
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "Account" */ "./Account"),
  loading: () => null,
  modules: ["Account"],
});
/**
 * Loadable import for UserProfile container
 */
const UserProfile = Loadable({
  loader: () => import(/* webpackChunkName: "UserProfile" */ "./UserProfile"),
  loading: () => null,
  modules: ["UserProfile"],
});
/**
 * Loadable import for UserProfile container
 */
const Users = Loadable({
  loader: () => import(/* webpackChunkName: "Users" */ "./Users"),
  loading: () => null,
  modules: ["Users"],
});
/**
 * Loadable import for UserProfile container
 */
const Dishes = Loadable({
  loader: () => import(/* webpackChunkName: "Dishes" */ "./Dishes"),
  loading: () => null,
  modules: ["Dishes"],
});
/**
 * Loadable import for UserProfile container
 */
const DishDetails = Loadable({
  loader: () => import(/* webpackChunkName: "DishDetails" */ "./DishDetails"),
  loading: () => null,
  modules: ["DishDetails"],
});

/**
 * Loadable import for UserProfile container
 */
const Product = Loadable({
  loader: () => import(/* webpackChunkName: "Product" */ "./Product"),
  loading: () => null,
  modules: ["Product"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
class Root extends Component {
  render() {
    return (
      <StickyContainer id="Root">
        <OfflineBar />
        {/* <Sticky topOffset={1} disableCompensation>
          { ({style, isSticky}) => <Navbar style={ {...style} } isSticky={isSticky}/>}
        </Sticky> */}
        <div>
          <Switch>
            <Route path={"/"} exact component={Dashboard} />
            <Route path={"/dish/:id"} component={DishDetails} />
            <Route path={"/healthy-fact/:id"} component={HealthyFact} />
            <Route path={"/product/:id"} component={Product} />
            {/* <PrivateRoute
              exact
              path="/articles/:id"
              component={ArticleDetails}
            /> */}
            <PrivateRoute path={"/dashboard"} component={Dashboard} />

            {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
            {/* new routes */}

            <Route component={FourOFour} />
          </Switch>
        </div>
        <Footer />
      </StickyContainer>
    );
  }
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
